export const environment = {

  api: {
	host: 'https://api-pob.mpsprod.alzp.tgscloud.net'
  },
  front: {
    host: 'https://pob.totalenergies.com'
  },
  production: true,
  google: {
	siteKey: '6LdtZsceAAAAAI5UK-L2Ub8T7Wy4oBmxV-dHQiit'
  },
  reCaptchaKey : '6LdtZsceAAAAADsKDi5Qb4C28Pkd1Ol3t5mcm3rR',
};
